import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TYPES_API_BASE_PATH } from "../../constant/apiEndPoint.constant";
import { Axios } from "../../axios/axios";

export interface TypesInterface {
  loading: boolean;
  data: any;
  error: any;
}

const initialState: TypesInterface = {
  loading: false,
  data: [],
  error: null,
};

// Async thunk action creator
export const typesState = createAsyncThunk(
  "typesSlice/typesState",
  async () => {
    const { data } = await Axios.get(TYPES_API_BASE_PATH);
    return data;
  }
);

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    // Other reducers for your slice
  },
  extraReducers: (builder) => {
    builder
      .addCase(typesState.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(typesState.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(typesState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default toastSlice.reducer;
