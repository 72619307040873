import React from "react";
import SectionHeader from "../SectionHeader";

interface ModalHeaderInterface {
  title: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModalHeader = ({ title }: ModalHeaderInterface) => {
  return (
    <>
      <div className="bg-primaryblue/10 text-start">
        {/* <h3 className="text-18px text-black leading-6 font-bold font-poppins">git
          {title}
        </h3> */}
        <SectionHeader text={title} />
        {/* <div
          className="absolute right-5 top-5 w-7 h-7 rounded-3xl bg-purple flex items-center justify-center cursor-pointer"
          onClick={() => setShowModal(false)}
        >
          <svg
            className="fill-white w-[10px]"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.7188 14.3906L9.32501 8.00037L15.7188 1.65714C16.0392 1.28603 16.0219 0.729108 15.679 0.378943C15.3361 0.0287773 14.7832 0.00341392 14.4101 0.320731L7.99765 6.6169L1.69734 0.273674C1.33275 -0.0912247 0.743918 -0.0912247 0.379325 0.273674C0.202363 0.450387 0.102824 0.690937 0.102824 0.941878C0.102824 1.19282 0.202363 1.43337 0.379325 1.61008L6.67028 7.9439L0.276501 14.2777C0.0995389 14.4544 0 14.695 0 14.9459C0 15.1969 0.0995389 15.4374 0.276501 15.6141C0.452909 15.7903 0.69172 15.8885 0.940183 15.887C1.18409 15.8885 1.41891 15.794 1.59452 15.6235L7.99765 9.27089L14.4101 15.7271C14.5865 15.9032 14.8253 16.0014 15.0738 16C15.319 15.9989 15.554 15.9009 15.7281 15.7271C15.9039 15.5491 16.0017 15.3078 16 15.0569C15.9982 14.8059 15.897 14.5661 15.7188 14.3906Z" />
          </svg>
        </div> */}
      </div>
    </>
  );
};

export default ModalHeader;
