import { ADMIN_NAVIGATION, STUDENT_NAVIGATION } from "./navigation.constant";

export interface MenuLinks {
  name: string;
  link?: string;
  onClickFunc?: () => void;
}

export const adminMenuLinksFunc: (onClickFunc: () => void) => MenuLinks[] = (
  onClickFunc
) => [
  {
    name: "Home",
    link: ADMIN_NAVIGATION.home,
  },
  {
    name: "Students",
    link: ADMIN_NAVIGATION.students,
  },
  // {
  //   name: "Pricing",
  //   link: ADMIN_NAVIGATION.pricing,
  // },
  {
    name: "Plans",
    link: ADMIN_NAVIGATION.plans.view,
  },
  // {
  //   name: "Blogs",
  //   link: ADMIN_NAVIGATION.blogs.view,
  // },
  {
    name: "Lessons",
    link: ADMIN_NAVIGATION.lesson.view,
  },
  {
    name: "Practice Exams",
    link: ADMIN_NAVIGATION.practiceExams,
  },
  {
    name: "Practice Exam Categories",
    link: ADMIN_NAVIGATION.mockExamsCategory,
  },
  {
    name: "Mock Exams",
    link: ADMIN_NAVIGATION.mockExams.view,
  },

  {
    name: "CMS Pages",
    link: ADMIN_NAVIGATION.cmsPages.view,
  },
  {
    name: "Log Out",
    onClickFunc,
  },
];

export const stduentMenuLinksFunc: (onClickFunc: () => void) => MenuLinks[] = (
  onClickFunc
) => [
  {
    name: "Home",
    link: STUDENT_NAVIGATION.home,
  },
  {
    name: "Plans",
    link: STUDENT_NAVIGATION.plans,
  },
  {
    name: "Practice Exams",
    link: STUDENT_NAVIGATION.practiceExams,
  },
  {
    name: "Mock Exams",
    link: STUDENT_NAVIGATION.mockExams,
  },
  {
    name: "Practice Exam Results",
    link: STUDENT_NAVIGATION.practiceExamresults,
  },
  {
    name: "Mock Exam Results",
    link: STUDENT_NAVIGATION.mockExamresults,
  },
  {
    name: "Lessons",
    link: STUDENT_NAVIGATION.lessons,
  },
  {
    name: "User Profile",
    link: STUDENT_NAVIGATION.studentProfile,
  },
  {
    name: "Log Out",
    onClickFunc,
  },
];
