// Create a separate component or custom React Hook to handle logout and redirection
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { UserSetToNull } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const { isAdmin } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutFunc = () => {
    localStorage.removeItem("access_token");
    dispatch(UserSetToNull());
    if (isAdmin) {
      navigate("/admin/login");
    } else {
      navigate("/login");
    }
  };

  return logOutFunc;
};

export default useLogout;
