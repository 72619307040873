interface ButtonProps {
  parentClass?: string;
  classname?: string;
  children: JSX.Element | string;
  onClickHandler?: (e?: any) => void;
  varient?: "white" | undefined;
  // smallBtn?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onlyDisable?: boolean;
  loader?: JSX.Element;
  bigButton?: boolean;
  btnRef?: any;
  style?: any;
}

const Button = (props: ButtonProps) => {
  return (
    <>
      <div
        className={`${props.parentClass ? props.parentClass : ""} relative z-0`}
      >
        <button
          style={props.style && props.style}
          ref={props.btnRef && props.btnRef}
          type={`${props.type ? props.type : "button"}`}
          onClick={() => props.onClickHandler && props.onClickHandler()}
          disabled={props.onlyDisable ? true : false}
          className={`
          ${props.classname ? props.classname : ""} 
          ${
            props.bigButton
              ? "py-4 px-6 text-lg/4 "
              : "px-3 py-1 text-xs/4 font-bold tracking-wider"
          } 
          ${
            props.varient === "white"
              ? " bg-white text-purple hover:bg-purple hover:text-purple"
              : "bg-purple text-white hover:bg-purple/80 "
          }
          ${props.onlyDisable ? "cursor-not-allowed" : ""}
       !border !border-solid border-purple hover:bg-white hover:text-purple active:scale-95 transition-all duration-300  rounded-full `}
        >
          {props.loader}
          {props.children}
        </button>
      </div>
    </>
  );
};

export default Button;
