export const PUBLIC_NAVIGATION = {
  signup: "registration",
  login: "login",
  adminLogin: "admin/login",
  pageNotFound: "404",
  forgotPassword: "forgot-password",
  setPassword: "reset-password",
  verifyEmail: "verify-email",
  paymentSuccess: "payment-success",
  home: "",
  teams: "meet-our-james-ruse-team",
  blogs: "blog",
  faqs: "faqs-frequently-asked-questions",
  pricing: "pricing-plans",
  blogDetail: "blogs/:slug",
  privacyPolicy: "privacy-policy",
  terms: "terms-and-conditions",
};

export const ADMIN_NAVIGATION = {
  home: "admin/",
  students: "admin/students",
  studentTestDetails: "admin/students/:userId",
  pricing: "admin/pricing",
  plans: {
    view: "admin/plans",
    add: "admin/plans/add",
    edit: "admin/plans/edit/:planId",
  },

  lesson: {
    view: "admin/lesson",
    add: "admin/lesson/add",
    edit: "admin/lesson/edit/:lessonId",
  },
  mockExams: {
    view: "admin/mock-exams",
    add: "admin/mock-exams/add",
    edit: "admin/mock-exams/edit/:mockExamId",
  },
  cmsPages: {
    blogs: {
      view: "admin/cms-pages/blogs",
      add: "admin/cms-pages/blogs/add",
      edit: "admin/cms-pages/blogs/edit/:blogId",
    },
    blog: "admin/cms-pages/blog",
    blogCategory: "admin/cms-pages/blogs/categories",
    blogAuthor: "admin/cms-pages/blogs/authors",
    view: "admin/cms-pages",
    viewOneCmsPage: "admin/cms-pages/:cmsPageId",
  },
  mockExamsCategory: "admin/practice-exams-category",
  practiceExams: "admin/practice-exams",
  mockExamSubCategory: "admin/practice-exams-category/subcategory/:categoryId",
  questions: {
    view: "admin/practice-exams/questions/:examId",
    add: "admin/practice-exams/questions/:examId/add",
    edit: "admin/practice-exams/questions/:examId/edit/:questionId",
  },
  answer: "admin/practice-exams/questions/answers/:questionId",

  mockExamresultsPreview: "admin/mock-exam-results/preview/:mockExamId",
  practiceExamresultsPreview: "admin/practice-exam-results/preview/:resultId",
  cmsTeams: "admin/cms-pages/teams",
  cmsfaq: "admin/cms-pages/faq",
};

export const STUDENT_NAVIGATION = {
  home: "",
  plans: "plans",
  paymentuccess: "payment/success",
  paymentCancel: "payment/cancel",
  mockExams: "mock-exams",
  practiceExams: "practice-exams",
  students: "students",
  practiceExamresults: "practice-exam-results",
  mockExamresults: "mock-exam-results",
  mockExamresultsPreview:
    "mock-exam-results/preview/:mockExamId/:subscriptionId",
  lessons: "lessons",
  practiceExamQuestion: "practice-exams/question/:practiceExamId",
  mockPracticeExams: "mock-exams/practice-exam/:mockExamId",
  mockPracticeExamsQuestions:
    "mock-exams/practice-exam/questions/:mockExamId/:practiceExamId",
  practiceExamresultsPreview: "practice-exam-results/preview/:resultId",
  lessonDetails: "lessons/lesson-detail/:lessonId",
  studentProfile: "profile",
};
