import {
  ADMIN_NAVIGATION,
  PUBLIC_NAVIGATION,
  STUDENT_NAVIGATION,
} from "../constant/navigation.constant";

import { lazy } from "react";

export interface RoutesInterface {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
  isPublic?: boolean;
}
export const adminRoutes: RoutesInterface[] = [
  {
    path: ADMIN_NAVIGATION.home, // default
    element: lazy(() => import("../pages/home")),
  },
  {
    path: ADMIN_NAVIGATION.students,
    element: lazy(() => import("../pages/students")),
  },
  // {
  //   path: ADMIN_NAVIGATION.pricing,
  //   element: lazy(() => import("../pages/pricing")),
  // },
  {
    path: ADMIN_NAVIGATION.plans.view,
    element: lazy(() => import("../pages/plans")),
  },
  {
    path: ADMIN_NAVIGATION.plans.add,
    element: lazy(() => import("../pages/addEditPlan")),
  },
  {
    path: ADMIN_NAVIGATION.plans.edit,
    element: lazy(() => import("../pages/addEditPlan")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blogs.view,
    element: lazy(() => import("../pages/blogs")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blogs.add,
    element: lazy(() => import("../pages/addEditBlog")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blogs.edit,
    element: lazy(() => import("../pages/addEditBlog")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blogCategory,
    element: lazy(() => import("../pages/blogCategory")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blogAuthor,
    element: lazy(() => import("../pages/blogAuthor")),
  },

  {
    path: ADMIN_NAVIGATION.lesson.view,
    element: lazy(() => import("../pages/lesson")),
  },
  {
    path: ADMIN_NAVIGATION.lesson.add,
    element: lazy(() => import("../pages/addEditLesson")),
  },
  {
    path: ADMIN_NAVIGATION.lesson.edit,
    element: lazy(() => import("../pages/addEditLesson")),
  },
  {
    path: ADMIN_NAVIGATION.practiceExams,
    element: lazy(() => import("../pages/practiceExam")),
  },
  {
    path: ADMIN_NAVIGATION.mockExams.view,
    element: lazy(() => import("../pages/mockExam")),
  },
  {
    path: ADMIN_NAVIGATION.mockExams.add,
    element: lazy(() => import("../pages/addEditMockExam")),
  },
  {
    path: ADMIN_NAVIGATION.mockExams.edit,
    element: lazy(() => import("../pages/addEditMockExam")),
  },
  {
    path: ADMIN_NAVIGATION.mockExamsCategory,
    element: lazy(() => import("../pages/mockExamCategory")),
  },
  {
    path: ADMIN_NAVIGATION.mockExamSubCategory,
    element: lazy(() => import("../pages/mockExamSubCategory")),
  },
  {
    path: ADMIN_NAVIGATION.questions.view,
    element: lazy(() => import("../pages/question")),
  },
  {
    path: ADMIN_NAVIGATION.questions.add,
    element: lazy(() => import("../pages/addEditQuestion")),
  },
  {
    path: ADMIN_NAVIGATION.questions.edit,
    element: lazy(() => import("../pages/addEditQuestion")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.blog,
    element: lazy(() => import("../pages/blogs")),
  },
  {
    path: ADMIN_NAVIGATION.studentTestDetails,
    element: lazy(() => import("../pages/studentAttempt")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.view,
    element: lazy(() => import("../pages/cmsPages")),
  },
  {
    path: ADMIN_NAVIGATION.cmsPages.viewOneCmsPage,
    element: lazy(() => import("../pages/cmsPageView")),
  },
];

export const studentRoutes: RoutesInterface[] = [
  {
    path: STUDENT_NAVIGATION.home,
    element: lazy(() => import("../pages/studentHome")),
  },
  {
    path: STUDENT_NAVIGATION.plans,
    element: lazy(() => import("../pages/studentPlans")),
  },
  {
    path: STUDENT_NAVIGATION.paymentuccess,
    element: lazy(() => import("../pages/planSuccess")),
  },
  {
    path: STUDENT_NAVIGATION.paymentCancel,
    element: lazy(() => import("../pages/planCancel")),
  },
  {
    path: STUDENT_NAVIGATION.mockExams,
    element: lazy(() => import("../pages/studentsMockExams")),
  },
  {
    path: STUDENT_NAVIGATION.practiceExams,
    element: lazy(() => import("../pages/studentsPracticeExams")),
  },
  {
    path: STUDENT_NAVIGATION.practiceExamresults,
    element: lazy(() => import("../pages/practiceExamResults")),
  },
  {
    path: STUDENT_NAVIGATION.mockExamresults,
    element: lazy(() => import("../pages/mockExamResult")),
  },
  {
    path: STUDENT_NAVIGATION.practiceExamresultsPreview,
    element: lazy(() => import("../pages/practiceExamResultPreview")),
  },
  {
    path: STUDENT_NAVIGATION.mockExamresultsPreview,
    element: lazy(() => import("../pages/mockExamResultPreview")),
  },
  {
    path: STUDENT_NAVIGATION.lessons,
    element: lazy(() => import("../pages/studentLesson")),
  },
  {
    path: STUDENT_NAVIGATION.mockPracticeExams,
    element: lazy(() => import("../pages/studentMockPracticeExams")),
  },
  {
    path: STUDENT_NAVIGATION.practiceExamQuestion,
    element: lazy(() => import("../pages/studentPracticeExamQuestion")),
  },
  {
    path: STUDENT_NAVIGATION.mockPracticeExamsQuestions,
    element: lazy(() => import("../pages/studentMockPracticeExamQuestion")),
  },
  {
    path: STUDENT_NAVIGATION.lessonDetails,
    element: lazy(() => import("../pages/studentLessonDetail")),
  },
  {
    path: STUDENT_NAVIGATION.studentProfile,
    element: lazy(() => import("../pages/studentProfile")),
  },
];

export const publicRoutes: RoutesInterface[] = [
  {
    path: PUBLIC_NAVIGATION.adminLogin,
    element: lazy(() => import("../pages/authentication/AdminLogin")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.login,
    element: lazy(() => import("../pages/authentication")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.signup,
    element: lazy(() => import("../pages/authentication/SignUp")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.forgotPassword,
    element: lazy(() => import("../pages/authentication/ForgotPassword")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.setPassword,
    element: lazy(() => import("../pages/authentication/ResetPassword")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.verifyEmail,
    element: lazy(() => import("../pages/authentication/verifyEmail")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.teams,
    element: lazy(() => import("../pages/teams")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.blogs,
    element: lazy(() => import("../pages/publicBlogs")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.faqs,
    element: lazy(() => import("../pages/faqs")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.blogDetail,
    element: lazy(() => import("../pages/publicBlogDetail")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.home,
    element: lazy(() => import("../pages/publicHome")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.pricing,
    element: lazy(() => import("../pages/publicPricing")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.privacyPolicy,
    element: lazy(() => import("../pages/publicPrivacyPolicy")),
    isPublic: true,
  },
  {
    path: PUBLIC_NAVIGATION.terms,
    element: lazy(() => import("../pages/publicTerms")),
    isPublic: true,
  },
];
