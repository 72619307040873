import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import toastReducer from "./slices/toastSlice";
import typesReducer from "./slices/formTypesSlice";
import studentPracticeExamReducer from "./slices/studentPracticeExamSlice";
import studentMockExamReducer from "./slices/studentMockExamSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  types: typesReducer,
  practiceExam: studentPracticeExamReducer,
  mockExam: studentMockExamReducer,
});

export default rootReducer;
