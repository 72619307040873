import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  RoutesInterface,
  adminRoutes,
  publicRoutes,
  studentRoutes,
} from "./routes";
import Layout from "../components/Layout";
import NotFound from "../components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { typesState } from "../redux/slices/formTypesSlice";
import BraindocLoader from "../components/Loader/BraindocLoader";
import { UserSet } from "../redux/slices/authSlice";
import { useGetLoggedInUser } from "../hooks/useLogin";
const FinalElement = (props: {
  isPublic?: boolean;
  element: React.LazyExoticComponent<() => React.ReactElement>;
}) => {
  const { isPublic } = props;
  return isPublic ? (
    <props.element />
  ) : (
    <Layout>
      <props.element />
    </Layout>
  );
};

interface RouteAttribute {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
  isPublic?: boolean;
}

export const Router = () => {
  return (
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  );
};

const MainRouter = () => {
  const [routes, setRoutes] = useState<RoutesInterface[]>(publicRoutes);
  const [fetchingUser, setFetchingUser] = useState(false);
  const { isAuthenticated, isAdmin } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { getLoggedInUser } = useGetLoggedInUser();
  useEffect(() => {
    dispatch(typesState());
  }, []);
  useLayoutEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      if (!isAuthenticated) {
        loadUser()
          .then((data) => {
            setRoutes(data?.isAdmin ? adminRoutes : studentRoutes);
            dispatch(UserSet(data));
          })
          .finally(() => {
            setFetchingUser(false);
          });
      }
    } else {
      setRoutes(publicRoutes);
    }
  }, [navigate]);

  const loadUser = async () => {
    try {
      setFetchingUser(true);
      const { data } = await getLoggedInUser();
      return data;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (
        publicRoutes.some((url: RoutesInterface) => {
          return `/${url.path}` === location.pathname;
        })
      ) {
        navigate(isAdmin ? "/admin/" : "/");
      }
    }
  }, [isAuthenticated]);
  return (
    <>
      {fetchingUser ? (
        <BraindocLoader />
      ) : (
        <Suspense fallback={<BraindocLoader />}>
          <Routes>
            <Route path="/">
              {routes.map((route: RouteAttribute) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<FinalElement {...route} />}
                />
              ))}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      )}
    </>
  );
};
