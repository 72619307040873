import { createSlice } from "@reduxjs/toolkit";
import { StudentExam } from "../types";

const initialState: StudentExam = {
  examId: null,
  attemptData: [],
};

const studentPracticeExamSlice = createSlice({
  name: "studentPracticeExam",
  initialState,
  reducers: {
    addExam(state, action) {
      state.examId = action.payload;
    },
    addAnswer(state, action) {
      state.attemptData = [...state.attemptData, action.payload];
    },
    addExamNotSubmitted(_state, action) {
      return action.payload;
    },
    updateAnswer(state, action) {
      state.attemptData = action.payload;
    },
    removeExam(state) {
      state.examId = null;
      state.attemptData = [];
    },
  },
});
export const {
  addAnswer,
  updateAnswer,
  addExam,
  removeExam,
  addExamNotSubmitted,
} = studentPracticeExamSlice.actions;
export default studentPracticeExamSlice.reducer;
