import axios, { AxiosRequestConfig } from "axios";
import { useState } from "react";
import { REACT_APP_API_URL } from "../config";
import store from "../redux/store";
import { removeToast, setToast } from "../redux/slices/toastSlice";

export const requestTypes = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
};

export const useApi = (config?: AxiosRequestConfig) => {
  const [state, setState] = useState({
    loading: false,
    error: null,
    data: null,
  });

  const makeApiCall = (additionalConfig: AxiosRequestConfig = {}) => {
    const configOptions = { ...config, ...additionalConfig };
    setState((old) => ({ ...old, loading: true }));
    const authToken = localStorage.getItem("access_token");
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: REACT_APP_API_URL + configOptions.url,
          method: configOptions.method,
          data: configOptions.data,
          params: configOptions.params,
          headers: {
            ...(authToken && {
              Authorization: `JWT ${authToken}`,
            }),
            ...configOptions.headers,
          },
        })
        .then((res) => {
          const { toast } = res.data;
          if (toast) {
            const toastId = new Date().getTime();
            store.dispatch(
              setToast({
                message: res.data.message,
                id: toastId,
              })
            );
            setTimeout(() => {
              store.dispatch(removeToast({ id: toastId }));
            }, 2000);
          }
          const old = { ...state };
          if (res.data) {
            old.data = res.data;
          }
          old.loading = false;
          setState(old);
          resolve(old);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("access_token");
            window.location.href = "/login";
          }
          if (
            e.response.status === 400 ||
            e.response.status === 500 ||
            e.response.status === 401
          ) {
            const { toast } = e.response.data;
            if (toast) {
              const toastId = new Date().getTime();
              store.dispatch(
                setToast({
                  message: e.response.data.message,
                  // type: e.response.data.responseType,
                  id: toastId,
                })
              );
              setTimeout(() => {
                store.dispatch(removeToast({ id: toastId }));
              }, 2000);
            }
          }
          if (e.message) {
            setState((old) => ({
              ...old,
              loading: false,
              error: e.message,
            }));
          } else {
            setState((old) => ({
              ...old,
              loading: false,
              error: "Error while processing",
            }));
          }
          reject(e);
        });
    });
  };
  return { ...state, refresh: makeApiCall };
};
