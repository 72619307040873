import { USER_API_BASE_PATH } from "../constant/apiEndPoint.constant";
import { requestTypes, useApi } from "./useApi";

export const useGetLoggedInUser = () => {
  const { refresh, ...rest } = useApi();
  const getLoggedInUser = async () => {
    const res: any = await refresh({
      method: requestTypes.GET,
      url: `${USER_API_BASE_PATH}/currentuser`,
    });
    return res.data;
  };
  return { getLoggedInUser, ...rest };
};
