import { useDispatch, useSelector } from "react-redux";
import {
  ToastInterface,
  getToast,
  removeToast,
} from "../../redux/slices/toastSlice";
import { AppDispatch } from "../../redux/store";
import Button from "../Button";

const Toast = () => {
  const toastMessage = useSelector(getToast);
  const dispatch: AppDispatch = useDispatch();
  return (
    <>
      {toastMessage.length !== 0 ? (
        <div>
          {toastMessage.map((toast: ToastInterface) => (
            <div
              key={toast.id}
              className="bg-white shadow-xl px-4  max-w-md py-5 fixed top-0 right-0 z-[999] w-full"
            >
              <p className="text-base text-purple font-bold text-center">
                {toast.message}
              </p>
              <Button
                classname="hidden"
                onClickHandler={() => dispatch(removeToast({ id: toast.id }))}
              >
                <></>
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Toast;
