import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../types";

const initialState: UserState = {
  user: null,
  isAuthenticated: false,
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    UserSet(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isAdmin = action.payload?.isAdmin || false;
    },
    UserSetToNull(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.isAdmin = false;
    },
  },
});
export const { UserSet, UserSetToNull } = authSlice.actions;
export default authSlice.reducer;
