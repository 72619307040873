import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center w-screen h-screen relative overflow-hidden">
      <div className="w-60 h-60 md:w-96 md:h-96 rounded-full bg-gradient-to-r to-purple/80 from-purpledark absolute -left-40 top-6 md:-left-56 md:top-14  xl:-left-40 xl:top-32"></div>
      <div className="w-60 h-60 md:w-96 md:h-96 rounded-full bg-gradient-to-tr to-purpledark from-purple/80 absolute -right-40 bottom-6 md:-right-72 md:bottom-14 xl:-right-52 xl:top-1/2 "></div>
      <div className="px-5 py-5 bg-white  font-poppins">
        <div className="flex flex-col items-center">
          <div className="py-10">
            <img
              src="assets/images/BrainDoc.svg"
              className="w-56"
              alt="pagenotfound"
            />
          </div>
          <h1 className="font-bold text-purple text-9xl  md:text-[200px] md:leading-[1] flex items-baseline justify-center gap-2 ">
            <span className="-mr-4">4</span>
            <span>
              <img
                src="assets/images/pagenotfound-cap.png"
                className="w-28 md:w-48"
                alt="pagenotfound"
              />
            </span>
            <span className="ml-3">4</span>
          </h1>

          <h6 className="mb-2 text-2xl font-semibold  text-center text-gray-800 md:text-4xl">
            <span className="text-purple">Oops!</span> Page not found
          </h6>

          <p className="mb-8 text-center text-gray-500 md:text-lg">
            The page you’re looking for doesn’t exist.
          </p>

          <div
            className="bg-purple px-8 py-2.5 text-base font-semibold rounded-full text-white shadow-lg cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
