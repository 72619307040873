import React from "react";
import ModalHeader from "./ModalHeader";
import ReactDom from "react-dom";

interface PropsInterface {
  children: React.ReactNode;
  title?: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  size: string;
}

export const Modal = ({
  children,
  title,
  showModal,
  setShowModal,
  size,
}: PropsInterface) => {
  if (!showModal) return null;
  return ReactDom.createPortal(
    <>
      <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 p-8 xl:p-0 before:content-[''] before:backdrop-blur-sm before:absolute before:w-full before:h-full before:top-0 before:left-0 before:bg-black/20 before-bg-blur">
        <div
          className={`${`relative ${size} xl:${size} my-6 mx-auto rounded-xl`}`}
        >
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-6 md:px-8 py-5">
            {title && <ModalHeader title={title} setShowModal={setShowModal} />}
            {children}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root") as HTMLElement
  );
};
