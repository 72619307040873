import { useState } from "react";
import { Sidebar } from "./Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
// import { IconSidebar } from "./IconSidebar";

const Layout = ({ children }: { children?: React.ReactNode }) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const attemptPracticeExamReduxData = useSelector(
    (state: RootState) => state.practiceExam
  );
  const attemptMockExamReduxData = useSelector(
    (state: RootState) => state.mockExam
  );

  return (
    <>
      <div className="flex">
        {attemptPracticeExamReduxData.examId == null &&
        attemptMockExamReduxData.examId === null ? (
          <Sidebar />
        ) : (
          <></>
          // <IconSidebar />
        )}
        <div className="max-w-full xl:max-w-full w-full px-35px py-9">
          <div className="flex xl:hidden items-center justify-between bg-white mb-8 relative">
            {!showSidebar ? (
              <div onClick={() => setShowSidebar(true)}>
                <svg
                  className="w-6 fill-purple"
                  viewBox="0 0 52 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M51.5 35C51.5 36.3807 50.3807 37.5 49 37.5L3 37.5C1.61929 37.5 0.5 36.3807 0.5 35C0.5 33.6193 1.61929 32.5 3 32.5L49 32.5C50.3807 32.5 51.5 33.6193 51.5 35ZM44.5 19C44.5 20.3807 43.3807 21.5 42 21.5L10 21.5C8.61929 21.5 7.5 20.3807 7.5 19C7.5 17.6193 8.61929 16.5 10 16.5L42 16.5C43.3807 16.5 44.5 17.6193 44.5 19ZM49 5.5C50.3807 5.5 51.5 4.38071 51.5 3C51.5 1.61929 50.3807 0.5 49 0.5L3 0.5C1.61929 0.5 0.5 1.61929 0.5 3C0.5 4.38071 1.61929 5.5 3 5.5L49 5.5Z"
                  />
                </svg>
              </div>
            ) : (
              <div className="absolute h-full z-50 -top-9 -left-[35px] ">
                <Sidebar
                  mainClass="max-w-[303px] w-[110%] xl:hidden transition-all duration-500"
                  sideBarColFunc={() => setShowSidebar(false)}
                />
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
