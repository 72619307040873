interface SectionHeaderProps {
  parentClass?: string;
  text: string;
  textClass?: string;
}
const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <>
      <div
        className={`${
          props.parentClass ? props.parentClass : ""
        } relative mb-5 lg:mb-30px`}
      >
        <div className="flex flex-wrap">
          <div className="w-full h-fit relative before:absolute before:w-[2px] before:h-full before:bg-purple before:content-[''] before:left-0 before:top-0 pl-5">
            <h4
              className={`text-xl font-extrabold text-purple ${
                props.textClass ? props.textClass : ""
              }`}
            >
              {props.text}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHeader;
